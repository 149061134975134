import React, { useState, useRef, useEffect } from "react";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { CopyToClipboard } from "react-copy-to-clipboard";

//icons
import { MdDownload } from "react-icons/md";
import { IoIosCopy } from "react-icons/io";
import { BsFillShareFill } from "react-icons/bs";

//for share
//for image download
import { WhatsappShareButton } from "react-share";
import { saveAs } from "file-saver";
// react ga
import ReactGA from "react-ga";

const NewAllDisplayCard = ({ card }) => {
	const { photo_url, tags, title } = card;
	const [copy, setCopy] = useState(false);
	//isExpended
	const [isExpended, setIsExpended] = useState(false);

	function handleShareClick() {
		if (navigator.share) {
			const shareData = {
				title: "Image made by AI",
				text: `Check out this cool image made by AI!\n${photo_url}\n\nCheck out mind blowing AI made images on`,
				url: "",
			};
			navigator
				.share(shareData)
				.then(() => {
					console.log("Image shared successfully");
				})
				.catch((error) => {
					console.log("Error sharing image:", error);
				});
		}
	}

	return (
		<div className=" bg-black shadow-md w-full  md:hover:shadow-2xl rounded-md md:shadow-gray-900 md:hover:shadow-gray-700 shadow-gray-700 px-2 pt-2">
			<div className=" relative    rounded-lg ">
				<img className="rounded-t-lg" src={photo_url} alt="Shoes" />

				<div className="card-body relative sm:px-4 px-2 pb-4 min-h-[70px] text-white ">
					<h2 className="text-lg mt-2 mb-0">
						{!isExpended && title && title?.length > 100 ? (
							<span className="text-gray-400">
								${title?.slice(0, 100)}{" "}
								<span
									className="cursor-pointer italic hover:underline hover:text-indigo-500 text-indigo-400"
									onClick={() => setIsExpended(true)}
								>
									Read more...
								</span>
							</span>
						) : title && title?.length < 100 ? (
							<span className="text-gray-400">{title}</span>
						) : (
							<span className="text-gray-400">
								${title}{" "}
								<span
									className="cursor-pointer italic hover:underline text-indigo-500 hover:text-indigo-400"
									onClick={() => setIsExpended(false)}
								>
									{" "}
									Read less
								</span>
							</span>
						)}
					</h2>
					{/* <h2 className=" text-base text-gray-500">{tags.slice(1, -1)}</h2> */}
					{/* <ReactReadMoreReadLess
						charLimit={100}
						readMoreText={"Read more ▼"}
						readLessText={"Read less ▲"}
					>
						<span>{about}</span>
					</ReactReadMoreReadLess> */}
					<div
						onClick={handleShareClick}
						className="absolute opacity-50 cursor-pointer bottom-2 right-0   "
					>
						<BsFillShareFill className="text-gray-400 hover:text-gray-100   text-xl" />
					</div>
					<div className="absolute bottom-2 right-6 ">
						<CopyToClipboard text={title} onCopy={() => setCopy(true)}>
							{copy === true ? (
								<span className="text-gray-500">copied!</span>
							) : (
								<span className=" cursor-pointer  opacity-50">
									<IoIosCopy className="text-gray-400 hover:text-gray-100   text-xl" />
								</span>
							)}
						</CopyToClipboard>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewAllDisplayCard;
