import { useContext, useRef } from "react";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
//icons
import { FaBeer, FaHamburger, FaMoon, FaRegSun } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { FiMenu } from "react-icons/fi";
import { VscChromeClose } from "react-icons/vsc";
import { HiOutlineSearch } from "react-icons/hi";
import { FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";

import logo from "../Image/logo2.png";
import "./Menu.css";
import "../App.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { QueryContext } from "../../src/App.js";
import { click } from "@testing-library/user-event/dist/click";

function Menu() {
	const [mood, setMood] = useState(localStorage.getItem("theme") || "light");

	const { query, setQuery } = useContext(QueryContext);

	const [searchQuery, setSearchQuery] = useState("");

	const [isSidebar, setIsSidebar] = useState(false);
	const sidebarRef = useRef(null);

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, false);
		return () => {
			document.removeEventListener("click", handleClickOutside, false);
		};
	}, []);

	const handleClickOutside = (event) => {
		if (
			sidebarRef.current &&
			isSidebar &&
			!sidebarRef.current.contains(event.target)
		) {
			if (!isSidebar) return;

			setIsSidebar(false);
		}
	};

	const handleMood = () => {
		if (mood === "light") {
			setMood("dark");
		} else {
			setMood("light");
		}
	};

	useEffect(() => {
		localStorage.setItem("theme", mood);
		document.body.className = mood;
	}, [mood]);

	const handleSubmit = (e) => {
		e.preventDefault();
		setQuery(searchQuery);
	};

	return (
		<>
			<Navbar bg="black" style={{ overflow: "hidden" }} variant="dark">
				<div
					ref={sidebarRef}
					className="lg:ml-14 md:ml-6 ml-2 pt-2 flex w-full justify-between items-center "
				>
					<div
						className={`fixed ${
							!isSidebar && "translate-x-full"
						} duration-100 md:translate-x-full  ease-in top-0 bottom-0 z-50 right-0 w-[20rem] bg-slate-800`}
					>
						<div className="relative flex h-full  items-center">
							<span
								onClick={() => setIsSidebar(false)}
								className="absolute top-6 p-2 cursor-pointer rounded-full active:bg-slate-900 left-8"
							>
								<VscChromeClose className=" font-semibold text-gray-500 text-2xl hover:text-gray-400 " />
							</span>

							<div className="flex ml-12 -translate-y-24 flex-col space-y-2">
								<Link
									onClick={() => setIsSidebar(false)}
									className="hover:text-gray-300 text-gray-400 no-underline  font-semibold text-xl  p-2 bg-gray-800 rounded-sm  active:bg-slate-900 cursor-pointer"
									to="/"
								>
									<span>Home </span>
								</Link>
								<Link
									onClick={() => setIsSidebar(false)}
									className="hover:text-gray-300 text-gray-400 no-underline  font-semibold text-xl  p-2 bg-gray-800 rounded-sm  active:bg-slate-900 cursor-pointer"
									to="/meet"
								>
									<span>Meet the Team</span>
								</Link>
								<Link
									onClick={() => setIsSidebar(false)}
									to="/how-to-generate-ai-art"
									className="hover:text-gray-300 text-gray-400 no-underline  text-xl  font-semibold p-2 bg-gray-800 rounded-sm  active:bg-slate-900 cursor-pointer"
								>
									<span>How to generate AI art</span>
								</Link>
							</div>

							<div className="absolute -full bottom-10 flex justify-center ">
								<div className="grid ml-[100px] grid-flow-col gap-4">
									<div className="flex items-center">
										<a
											href="https://instagram.com/the.ai_kid?igshid=MTg0ZDhmNDA="
											target={"_blank"}
										>
											<i className="fab fa-facebook-f facebook-bg" />
											<FaInstagram className="text-2xl text-white"></FaInstagram>
										</a>
										<a
											href="https://www.linkedin.com/in/paras-madan-a9863716b"
											target={"_blank"}
										>
											<i className="fab fa-twitter twitter-bg" />
											<FaLinkedin className="text-2xl ml-5 text-white"></FaLinkedin>
										</a>
										<a
											href="https://youtube.com/@the-ai-kid"
											target={"_blank"}
										>
											<i className="fab fa-google-plus-g google-bg" />
											<FaYoutube className="text-2xl ml-5 text-white"></FaYoutube>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Link
						onClick={() => setIsSidebar(false)}
						style={{ display: "flex", alignItems: "center" }}
						to="/"
					>
						<img
							alt="logo"
							src={logo}
							className="d-inline-block md:h-20 h-14 mr-1 -mt-3  align-top"
						/>{" "}
					</Link>
					<form
						onSubmit={handleSubmit}
						className="flex-1 md:mr-0 mr-2  relative  md:flex hidden justify-center items-center"
					>
						<input
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
							type="text"
							placeholder="Search..."
							className="px-2 py-[6px] w-full text-gray-400 max-w-[500px]   bg-gray-800 focus:outline-none"
						/>
						<button className="py-[5.8px]  border-l-[0.5px] border-gray-900  cursor-pointer px-4 flex items-center justify-center  bg-gray-800 active:bg-gray-900 ">
							<HiOutlineSearch className="text-2xl text-gray-500 hover:text-gray-300" />
						</button>
						{/* <span
							onClick={() => setSearchQuery("")}
							className={`text-2xl ${
								searchQuery === "" && "hidden"
							}  absolute top-[2px] active:bg-gray-800 rounded-full p-1 right-[98px]  `}
						>
							<VscChromeClose className=" font-semibold  cursor-pointer text-gray-600 text-2xl hover:text-gray-400 " />
						</span> */}
					</form>
					<div className="md:flex hidden lg:space-x-2 space-x-1   mx-2">
						<Link
							className="hover:text-gray-300 text-gray-400 no-underline  font-semibold lg:text-base text-sm p-2 bg-gray-800 rounded-sm  active:bg-slate-900 cursor-pointer"
							to="/meet"
						>
							<span>Meet the Team</span>
						</Link>
						<Link
							to="/how-to-generate-ai-art"
							className="hover:text-gray-300 text-gray-400 no-underline  lg:text-base text-sm font-semibold p-2 bg-gray-800 rounded-sm  active:bg-slate-900 cursor-pointer"
						>
							<span>How to generate AI art</span>
						</Link>
					</div>
					<div className="flex md:hidden cursor-pointer mr-1">
						<span
							onClick={() => setIsSidebar(true)}
							className=" p-2 rounded-full active:bg-gray-800"
						>
							<FiMenu className="text-gray-500 text-3xl hover:text-gray-400" />
						</span>
					</div>
					{/* <Nav className="justify-content-end  nav-ber" activeKey="/home">
            <Nav.Item>

              <Nav.Link href=""><Link className={`Header ${mood}`} onClick={handleMood}><button className=' moodBtn flex items-center' style={{"borderRadius": "10%"} } variant = {mood}>{mood} {mood === 'light'?
                 <FaRegSun className='sun ml-2'></FaRegSun>:
                  <FaMoon className='mb-1 ml-2'></FaMoon>
                  }
                  </button></Link></Nav.Link>

            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/home"> Home</Nav.Link>
            </Nav.Item>
            <NavDropdown
              id="nav-dropdown-dark-example"
              title="Explore"
              menuVariant="dark"
            >
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
           
            <NavDropdown
              id="nav-dropdown-dark-example"
              title="Categories"
              menuVariant="dark"
            >
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Item>
              <Nav.Link eventKey="link-1">About</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-2">Service</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-3" href='#contact'>Contact</Nav.Link>
            </Nav.Item>
           </Nav> */}
				</div>
				{/* <div className='lg:hidden ber-item border'>
              <li className='text-white li-text'>
              <Nav.Link href=""><Link className={`Header ${mood}`} onClick={handleMood}><button className=' moodBtn flex items-center' style={{"borderRadius": "10%"} } variant = {mood}>{mood} {mood === 'light'?
                 <FaRegSun className='sun ml-2'></FaRegSun>:
                  <FaMoon className='mb-1 ml-2'></FaMoon>
                  }
                  </button></Link></Nav.Link>
             
                  <Nav.Link href="/home"> Home</Nav.Link>


                  <NavDropdown
              id="nav-dropdown-dark-example"
              title="Explore"
              menuVariant="dark"
            >
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
           
            <NavDropdown
              id="nav-dropdown-dark-example"
              title="Categories"
              menuVariant="dark"
            >
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link eventKey="link-1">About</Nav.Link>

            <Nav.Link eventKey="link-2">Service</Nav.Link>

            <Nav.Link eventKey="link-3" href='#contact'>Contact</Nav.Link>
              </li>
            </div>
        <GiHamburgerMenu className='text-white ber lg:hidden text-2xl mr-10'></GiHamburgerMenu> */}
			</Navbar>
		</>
	);
}

export default Menu;
