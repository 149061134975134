import React from 'react';
import { Outlet } from 'react-router-dom';
import Category from '../component/Catrgory/Category';
import Foot from '../component/Foot';
import Menu from '../component/Menu';
import Search from '../component/Search/Search';

const Main = () => {
    return (
        <div>
            <Menu></Menu>
            <Search></Search>
            <Category></Category>
            <Outlet></Outlet>
            <Foot></Foot>
        </div>
    );
};

export default Main;