import React from "react";

import P1 from "../../Image/midjourney/p1.jpg";
import P2 from "../../Image/midjourney/p2.jpg";
import P3 from "../../Image/midjourney/p3.jpg";
import P4 from "../../Image/midjourney/p4.jpg";
import P5 from "../../Image/midjourney/p5.jpg";

const HowToUseMidjourney = () => {
	return (
		<div className="flex flex-col bg-black text-gray-400 ">
			<div className=" ml-4 sm:ml-0 space-y-8 my-6 text-center ">
				<h2 className="mx-[1px] text-white md:text-5xl text-2xl  ">
					How to use generate{" "}
					<span className="text-indigo-400 font-bold">AI artwork: </span>A
					Step by Step guide
				</h2>
				{/* <div className="">
					<span className="font-semibold text-gray-400">
						By Tyler Lacoma
					</span>
					<br />
					<span className="text-gray-600">October 21, 2022</span>
				</div> */}
			</div>

			<div className="mx-auto mb-8 md:mt-6 pt-8 border-t-[0.4px] border-gray-400 text-gray-400  max-w-[1100px] ">
				<div className="sm:mx-4 mx-2 space-y-10  md:text-2xl text-xl ">
					<p>
						Are you ready to create mind-blowing AI art for yourself? Look
						no further! We've got you covered with a step-by-step guide
						that will take you through the process of generating these
						stunning AI-generated images for all of your projects and
						videos.
					</p>

					<p>
						And if you need a little inspiration, just head to our website
						and use the prompts provided to get started. Don't miss out on
						this opportunity to elevate your visual content and impress
						your audience. Start generating your own AI art today!
					</p>
					<h3 className="h3 md:text-5xl mt-4 text-white text-3xl">
						How to start using Midjourney
					</h3>
					<p>
						<span className="text-white font-semibold mr-2">Step 1:</span>{" "}
						Make sure you
						<a
							className="  no-underline  hover:underline  text-indigo-700 "
							href="https://discord.com/"
							target="_blank"
						>
							{" "}
							have a Discord login.{" "}
						</a>
						Midjourney works entirely on Discord, so you’ll need an
						account there to use it. Signing up for Discord is also free
						if you haven’t done it yet.
					</p>
					<p>
						<span className="text-white font-semibold mr-2">Step 2:</span>{" "}
						<a
							className="  no-underline  hover:underline text-indigo-700 "
							href="https://www.midjourney.com/home/"
							target="_blank"
						>
							{" "}
							Visit the Midjourney website.{" "}
						</a>
						Here, choose Join the beta. This will automatically take you
						to a Discord invite.
					</p>

					<div className="md:py-10  py-4">
						<img className="w-full  h-auto" src={P1} />
					</div>
					<p>
						<span className="text-white font-semibold mr-2">Step 3:</span>{" "}
						Accept the Discord invite to Midjourney. Choose to Continue to
						Discord.
					</p>
					<div className="md:py-10  py-4">
						<img className="w-full h-auto" src={P2} />
					</div>
					<p>
						<span className="text-white font-semibold mr-2">Step 4:</span>{" "}
						Your Discord app will not automatically open. When it does,
						select the ship-like Midjourney icon on the left menu.
					</p>
					<p>
						<span className="text-white font-semibold mr-2">Step 5:</span>{" "}
						In the Midjourney channels, locate the Newcomer rooms. There
						will typically be a number of newcomer rooms open, with names
						like “newbies-108.” You can select any of these to begin.{" "}
					</p>
					<div className="md:py-10  py-4">
						<img className="w-full h-auto" src={P3} />
					</div>
					<p>
						<span className="text-white font-semibold mr-2">Step 6:</span>{" "}
						Now you’re ready to begin creating AI art. Before you get
						started, note that you only have a certain number of prompt
						options available as part of your free trial. You can create
						around 25 free images. After that, you’ll have to purchase a
						full membership to continue. If you would rather not spend any
						money, it’s a good idea to take some time and think about just
						what you want to create on Midjourney. If you want, you can
						type “/help” to get a list of tips to peruse.{" "}
					</p>
					<p>
						<span className="text-white font-semibold mr-2">Step 7:</span>{" "}
						When ready, type “/imagine” in the Discord chat for your
						newbies room. This will create a prompt field where you can
						type the image description. The more precise that you can be
						with your description, the better the AI will be able to
						produce good results. Be descriptive, and if there’s a
						particular style that you are looking for, include it in your
						description. There are terms of conduct to follow here, but if
						you keep things clean, you shouldn’t have anything to worry
						about.
					</p>
					<p>
						When finished, select{" "}
						<span className="text-white font-semibold mr-2 italic">
							Enter
						</span>{" "}
						to send your prompt.
					</p>
					<div className="md:py-10  py-4">
						<img className="w-full h-auto" src={P4} />
					</div>

					<p>
						<span className="text-white font-semibold mr-2">Step 8:</span>{" "}
						Give Midjourney a minute to generate your images. Typically,
						the AI will create several different versions based on your
						description. You now have a number of options to continue.
					</p>
					<p>
						<br />
						Look below the images, and you’ll see a section of U and V
						buttons labeled 1 through 4. The numbers correspond to the
						four images that Midjourney produced. Choosing U will upscale
						that particular image into a larger, more defined version.
						Choosing V will create an all-new image based on the present
						image that you choose. You will also see a refresh button to
						the side to request a new set of images. Keep in mind that
						each of these choices will use up some of your available free
						prompts, so only do it if you are sure you want to proceed.
					</p>

					<p>
						<span className="text-white font-semibold mr-2">Step 9:</span>{" "}
						If you find an image that you like and choose U to upscale it,
						you will be given a new set of options. You can choose to Make
						variations on that image, Upscale to max before you download,
						or choose a Light upscale redo if it’s not quite what you
						wanted, among other options.
					</p>
					<p>
						When finished, you can DM the image directly to yourself by
						selecting the
						<span className="text-white font-semibold mr-2 italic">
							{" "}
							Envelope
						</span>{" "}
						emoji and adding it to your chat. That allows you to save and
						download the image whenever you want.
					</p>
					<div className="md:py-10  py-4">
						<img className="w-full h-auto" src={P5} />
					</div>

					<p>
						<span className="text-white font-semibold mr-2">
							Step 10:
						</span>{" "}
						If you plan on using a lot of Midjourney, you can use any bot
						channel in Midjourney’s Discord and type “/subscribe.” This
						will create a link that you can follow to pay for a
						subscription. Those who are really serious about using
						Midjourney in the long term will also want to
						<a
							className="  no-underline  hover:underline  text-indigo-700 "
							href="https://midjourney.gitbook.io/docs/user-manual"
							target="_blank"
						>
							{" "}
							take a look at the manual
						</a>
						, which will provide you with a greater list of commands and
						some advice about how to create images.
					</p>

					<p>
						For more AI image-generating options, check out
						<a
							className="  no-underline hover:underline  text-indigo-700 "
							href="https://www.digitaltrends.com/computing/microsoft-designer-app-offers-dall-e-experience-for-more-creative-projects/"
							target="_blank"
						>
							{" "}
							what Microsoft is doing in the field
						</a>
						, too.
					</p>
				</div>
			</div>
		</div>
	);
};

export default HowToUseMidjourney;
