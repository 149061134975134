import React from "react";
import P1 from "../../Image/about/p1.jpg";
import P2 from "../../Image/about/p2.jpg";
import P3 from "../../Image/about/p3.png";
import P4 from "../../Image/about/p4.jpg";
import { FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import { isMobile } from "react-device-detect";

const About = () => {
  return (
    <div className="bg-black pt-4 text-gray-400">
      <div className="mx-auto pl-8 pr-4 sm:max-w-[1000px] sm:px-8">
        <div>
          <div className="sm:pl-8 text-center sm:px-0">
            <h3 className="my-4 text-3xl sm:text-4xl ">
              <span className="text-accentOrange">Welcome </span>
              <span>
                to the biggest search engine of
                <span className="font-semibold text-indigo-500">
                  {" "}
                  AI Generated Images
                </span>
              </span>
            </h3>
            <p className="text-lg text-gray-600 ">
              We built this entire platform in just 7 days.
            </p>
          </div>
        </div>
        <div className="mx-auto max-w-[890px] py-14">
          <div className="mb-12">
            <div className={`flex flex-col ${isMobile ? '' : 'md:flex-row'} justify-center text-3xl sm:text-4xl xl:text-5xl`}>
              Meet the{" "}
              <span className="font-semibold text-white ml-1"> Team</span>
            </div>
          </div>
          <div className={`flex flex-col ${isMobile ? '' : 'md:flex-row'} justify-center`}>
            {/* Team Member 1 */}
            <div className={`bg-black shadow-md hover:shadow-2xl rounded-md hover:shadow-gray-700 shadow-gray-700 px-2 pt-2 ${isMobile ? 'mb-4' : 'md:mb-0 md:mr-4'} min-w-[300px] mx-2`}>
              <div className="relative rounded-lg">
                <img className="rounded-t-lg" src={P1} alt="Shoes" />
                <div className="card-body relative sm:px-4 px-2 pb-4 min-h-[70px] text-white ">
                  <h2 className="text-xl mt-[10px] mb-0 font-semibold">
                    Paras Madan
                  </h2>
                  <h2 className="text-base mt-0 ">
                    <span className="text-gray-400">
                      The AI and the Brain is handled by me{" "}
                    </span>
                  </h2>
                  <div className="flex mt-2 justify-center ">
                    <div className="grid grid-flow-col gap-4">
                      <div className="flex items-center">
                        {isMobile ? (
                          <a href="mailto:paras@cruxe.in" target={"_blank"}>
                            <SiGmail className="text-2xl text-white"></SiGmail>
                          </a>
                        ) : (
                          <a href="https://mail.google.com/mail/?view=cm&fs=1&to=paras@cruxe.in" target={"_blank"}>
                            <SiGmail className="text-2xl text-white"></SiGmail>
                          </a>
                        )}
                        <a href="https://instagram.com/the.ai_kid?igshid=MTg0ZDhmNDA=" target={"_blank"}>
                          <FaInstagram className="text-2xl ml-5 text-white"></FaInstagram>
                        </a>
                        <a href="https://www.linkedin.com/in/paras-madan-a9863716b" target={"_blank"}>
                          <FaLinkedin className="text-2xl ml-5 text-white"></FaLinkedin>
                        </a>
                        <a href="https://youtube.com/@the-ai-kid" target={"_blank"}>
                          <FaYoutube className="text-2xl ml-5 text-white"></FaYoutube>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Team Member 2 */}
            <div className={`bg-black shadow-md hover:shadow-2xl rounded-md hover:shadow-gray-700 shadow-gray-700 px-2 pt-2 ${isMobile ? 'mb-4' : 'md:mb-0 md:mr-4'} min-w-[300px] mx-2`}>
              <div className="relative rounded-lg">
                <img className="rounded-t-lg" src={P2} alt="Sanyam Dawar" />
                <div className="card-body relative sm:px-4 px-2 pb-4 min-h-[70px] text-white ">
                  <h2 className="text-xl mt-[10px] mb-0 font-semibold">
                    Sanyam Dawar
                  </h2>
                  <h2 className="text-base mt-0 ">
                    <span className="text-gray-400">
                      The Backend Architecture is made by me{" "}
                    </span>
                  </h2>
                  <div className="flex mt-2 justify-center ">
                    <div className="grid grid-flow-col gap-4">
                      <div className="flex items-center">
                        <a href="https://www.linkedin.com/in/sanyam-dawar-791511180/" target={"_blank"}>
                          <FaLinkedin className="text-2xl ml-5 text-white"></FaLinkedin>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Team Member 3 */}
            <div className={`bg-black shadow-md hover:shadow-2xl rounded-md hover:shadow-gray-700 shadow-gray-700 px-2 pt-2 ${isMobile ? 'mb-4' : 'md:mb-0'} min-w-[300px] mx-2`}>
              <div className="relative rounded-lg">
                <img className="rounded-t-lg" src={P4} alt="Medha Mittal" />
                <div className="card-body relative sm:px-4 px-2 pb-4 min-h-[70px] text-white ">
                  <h2 className="text-xl mt-[10px] mb-0 font-semibold">
                    Medha Mittal
                  </h2>
                  <h2 className="text-base mt-0 ">
                    <span className="text-gray-400">
                      I did the web scraping part
                    </span>
                  </h2>
                  <div className="flex mt-2 justify-center ">
                    <div className="grid grid-flow-col gap-4">
                      <div className="flex items-center">
                        <a href="https://www.linkedin.com/in/medha-mittal-2889a51ab/" target={"_blank"}>
                          <FaLinkedin className="text-2xl ml-5 text-white"></FaLinkedin>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Team Member 4 */}
            <div className={`bg-black shadow-md hover:shadow-2xl rounded-md hover:shadow-gray-700 shadow-gray-700 px-2 pt-2 ${isMobile ? 'mb-4' : 'md:mb-0 md:mr-4'} min-w-[300px] mx-2`}>
              <div className="relative rounded-lg">
                <img className="rounded-t-lg" src={P3} alt="Mohd Haider" />
                <div className="card-body relative sm:px-4 px-2 pb-4 min-h-[70px] text-white ">
                  <h2 className="text-xl mt-[10px] mb-0 font-semibold">
                    Mohd Haider
                  </h2>
                  <h2 className="text-base mt-0 ">
                    <span className="text-gray-400">
                      I made what you are seeing: The Fronted guy{" "}
                    </span>
                  </h2>
                  <div className="flex mt-2 justify-center ">
                    <div className="grid grid-flow-col gap-4">
                      <div className="flex items-center">
                        <a href="https://www.linkedin.com/in/mohd-haider-96a82620b/" target={"_blank"}>
                          <FaLinkedin className="text-2xl ml-5 text-white"></FaLinkedin>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>            
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
