import React, { useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import AllCard from '../AllCard/AllCard';
import Pagination from '../Paginaiton/Pagination';
import StoreCard from './StoreCard';


const Store = () => {
    const catDogStores = useLoaderData()
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(20);


    const lastPostIndex = currentPage * postsPerPage;
    const firstPostIndex = lastPostIndex - postsPerPage;
    const currentPosts = catDogStores.slice(firstPostIndex, lastPostIndex);

    console.log(catDogStores.length)
    return (
        <div> 
            
        </div>
        // <div>
        //      <div className='lg:grid lg:grid-cols-2 lg:ml-48'>
        //     {
        //         currentPosts && currentPosts?.map(catDogStor => <StoreCard
        //             key={catDogStor._id}
        //             catDogStor={catDogStor}
        //         ></StoreCard>)
                
        //     }

           
        // </div>
        //  <Pagination
        //         totalPosts={catDogStores.length}
        //         postsPerPage={postsPerPage}
        //         setCurrentPage={setCurrentPage}
        //         currentPage={currentPage}
        //     />
        //   </div>
            // <div>
            //     <AllCard></AllCard>
            // </div>
      
       
    );
};

export default Store;