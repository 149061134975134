import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Search.css'
const Search = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [idd, setId] = useState('')
    const handleSearch = data => {
        const id = data.id
        // console.log(id)
        setId(id)
    }
    console.log(idd)
    return (
        <div>
            
        </div>
        // <div className='flex justify-center mb-10 mt-28 '>
        //     {/* <Link to='/cats'><button>search</button></Link>  */}
        //     <div className='lg:flex search'>
        //         <form className='lg:flex' onSubmit={handleSubmit(handleSearch)}>
        //             <div className=''>
        //                 <label className="label"> <span className="label-text text-red-500">Choose search category name</span></label>
        //                 <select className="select select-bordered bg-gray-100 text-black w-96 max-w-xs" {...register("id")}>
        //                     <option>Select</option>
        //                     <option name='cat' value="639b0e14074a92f7c151da76">Cat</option>
        //                     <option name='dog' value="639b0e14074a92f7c151da77">Dog</option>

        //                 </select>
        //             </div>
        //             <div>
        //                 <label className="label lb"> <span className="label-text text-red-500">Click to set Choose</span></label>
        //                 <button className='btn btn-success ml-3' onClick={handleSearch}><span className='flex items-center'>set search id</span></button>
        //             </div>


        //         </form>
        //         <div>
        //         <label className="label lb"> <span className="label-text text-red-500">Search now</span></label>
        //         <Link to={`/store/${idd}`}><button className='p-2 border btn btn-secondary w-28 ml-3'><span className='flex items-center'><FaSearch></FaSearch> Search</span></button></Link>

        //         </div>
        //     </div>
        // </div>
    );
};

export default Search;