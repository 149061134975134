import React from "react";
import "./Footer.css";
import logo from "../Image/WhatsApp Image 2022-12-14 at 17.42.55.jpg";
import { FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { SiGmail } from "react-icons/si";

import India from "../Image/india.png";

import { isMobile } from "react-device-detect";

function Foot() {
	return (
		//   <footer className="footer-section" id='contact'>
		//         <div className="container">
		//             <div className="footer-cta pt-5 pb-5">
		//                 <div className="row">
		//                     <div className="col-xl-4 col-md-4 mb-30">
		//                         <div className="single-cta">
		//                             <i className="fas fa-map-marker-alt" />
		//                             <div className="cta-text">
		//                                 <h4>Find us</h4>
		//                                 <span>1010 Avenue, sw 54321, chandigarh</span>
		//                             </div>
		//                         </div>
		//                     </div>
		//                     <div className="col-xl-4 col-md-4 mb-30">
		//                         <div className="single-cta">
		//                             <i className="fas fa-phone" />
		//                             <div className="cta-text">
		//                                 <h4>Call us</h4>
		//                                 <span>9876543210 0</span>
		//                             </div>
		//                         </div>
		//                     </div>
		//                     <div className="col-xl-4 col-md-4 mb-30">
		//                         <div className="single-cta">
		//                             <i className="far fa-envelope-open" />
		//                             <div className="cta-text">
		//                                 <h4>Mail us</h4>
		//                                 <span>mail@info.com</span>
		//                             </div>
		//                         </div>
		//                     </div>
		//                 </div>
		//             </div>
		//             <div className="footer-content pt-5 pb-5">
		//                 <div className="row">
		//                     <div className="col-xl-4 col-lg-4 mb-50">
		//                         <div className="footer-widget">
		//                             <div className="footer-logo">
		//                                 <a href="index.html">
		//                                     <img
		//                                         src={logo}
		//                                         className="img-fluid"
		//                                         alt="logo"
		//                                     />
		//                                 </a>
		//                             </div>
		//                             <div className="footer-text">
		//                                 <h2 className='text-2xl text-gray-500'>
		//                                 Made with love by Paras.
		//                                 </h2>
		//                             </div>
		//                             <div className="footer-social-icon">
		//                                 <span>Follow us</span>
		//                                 <div className='flex'>
		//                                     <a href="https://instagram.com/the.ai_kid?igshid=MTg0ZDhmNDA=" target={'_blank'}>
		//                                     <i className="fab fa-facebook-f facebook-bg" />
		//                                     <FaInstagram className='text-2xl'></FaInstagram>
		//                                 </a>
		//                                 <a href="https://www.linkedin.com/in/paras-madan-a9863716b" target={'_blank'}>
		//                                     <i className="fab fa-twitter twitter-bg" />
		//                                     <FaLinkedin className='text-2xl'></FaLinkedin>
		//                                 </a>
		//                                 <a href="https://youtube.com/@the-ai-kid" target={'_blank'}>
		//                                     <i className="fab fa-google-plus-g google-bg" />
		//                                     <FaYoutube className='text-2xl'></FaYoutube>
		//                                 </a>
		//                                 </div>

		//                             </div>
		//                         </div>
		//                     </div>
		//                     <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
		//                         <div className="footer-widget">
		//                             <div className="footer-widget-heading">
		//                                 <h3>Useful Links</h3>
		//                             </div>
		//                             <ul>
		//                                 <li>
		//                                     <a href="#">Home</a>
		//                                 </li>
		//                                 <li>
		//                                     <a href="#">about</a>
		//                                 </li>
		//                                 <li>
		//                                     <a href="#">services</a>
		//                                 </li>
		//                                 <li>
		//                                     <a href="#">portfolio</a>
		//                                 </li>
		//                                 <li>
		//                                     <a href="#">Contact</a>
		//                                 </li>
		//                                 <li>
		//                                     <a href="#">About us</a>
		//                                 </li>
		//                                 <li>
		//                                     <a href="#">Our Services</a>
		//                                 </li>
		//                                 <li>
		//                                     <a href="#">Expert Team</a>
		//                                 </li>
		//                                 <li>
		//                                     <a href="#">Contact us</a>
		//                                 </li>
		//                                 <li>
		//                                     <a href="#">Latest News</a>
		//                                 </li>
		//                             </ul>
		//                         </div>
		//                     </div>
		//                     <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
		//                         <div className="footer-widget">
		//                             <div className="footer-widget-heading">
		//                                 <h3>Subscribe</h3>
		//                             </div>
		//                             <div className="footer-text mb-25">
		//                                 <p>
		//                                     Don’t miss to subscribe to our new feeds, kindly fill the form
		//                                     below.
		//                                 </p>
		//                             </div>
		//                             <div className="subscribe-form">
		//                                 <form action="#">
		//                                     <input type="text" placeholder="Email Address" />
		//                                     <button>
		//                                         <i className="fab fa-telegram-plane" />
		//                                     </button>
		//                                 </form>
		//                             </div>
		//                         </div>
		//                     </div>
		//                 </div>
		//             </div>
		//         </div>
		//       <div className="copyright-area">
		//           <div className="container">
		//               <div className="row">
		//                   <div className="col-xl-6 col-lg-6 text-center text-lg-left">
		//                       <div className="copyright-text">
		//                           <p>
		//                               Copyright © 2018, All Right Reserved{" "}
		//                               <a href="https://codepen.io/anupkumar92/">Anup</a>
		//                           </p>
		//                       </div>
		//                   </div>
		//                   <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
		//                       <div className="footer-menu">
		//                           <ul>
		//                               <li>
		//                                   <a href="#">Home</a>
		//                               </li>
		//                               <li>
		//                                   <a href="#">Terms</a>
		//                               </li>
		//                               <li>
		//                                   <a href="#">Privacy</a>
		//                               </li>
		//                               <li>
		//                                   <a href="#">Policy</a>
		//                               </li>
		//                               <li>
		//                                   <a href="#">Contact</a>
		//                               </li>
		//                           </ul>
		//                       </div>
		//                   </div>
		//               </div>
		//           </div>
		//       </div>
		//   </footer>
		<footer className="footer footer-center p-10 bg-gray-800 text-base-content rounded">
			<div className="grid grid-flow-col gap-4">
				<h4 className="text-white space-x-2 flex justify-center items-center">
					Made in
					<img className="h-8 mx-2" src={India} alt="India" />
					for the world
				</h4>
			</div>
			<div>
				<div className="grid grid-flow-col gap-4">
					<div className="flex items-center">
						{isMobile ? (
							<a href="mailto:paras@cruxe.in" target={"_blank"}>
								<SiGmail className="text-2xl text-white"></SiGmail>
							</a>
						) : (
							<a
								href="https://mail.google.com/mail/?view=cm&fs=1&to=paras@cruxe.in"
								target={"_blank"}
							>
								<SiGmail className="text-2xl text-white"></SiGmail>
							</a>
						)}
						<a
							href="https://www.instagram.com/parasmadan.in/"
							target={"_blank"}
						>
							<i className="fab fa-facebook-f facebook-bg" />
							<FaInstagram className="text-2xl ml-5 text-white"></FaInstagram>
						</a>
						<a
							href="https://www.linkedin.com/in/paras-madan-a9863716b"
							target={"_blank"}
						>
							<i className="fab fa-twitter twitter-bg" />
							<FaLinkedin className="text-2xl ml-5 text-white"></FaLinkedin>
						</a>
						<a href="https://www.instagram.com/parasmadan.in/" target={"_blank"}>
							<i className="fab fa-google-plus-g google-bg" />
							<FaYoutube className="text-2xl ml-5 text-white"></FaYoutube>
						</a>
					</div>
				</div>
			</div>
			<div>
				{/* <p>Copyright © 2022 - All right reserved by ACME Industries Ltd</p> */}
			</div>
		</footer>
	);
}

export default Foot;
