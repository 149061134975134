import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Category.css'

const Category = () => {
    const [categories, setCategories] = useState([])

    useEffect(() => {
        fetch('https://ai-project-server.vercel.app/category')
        .then(res => res.json())
        .then(data => setCategories(data))

    }, [])
    return (
        <div className=''>
            {/* <div>
                <div className='flex justify-center mb-6 '>
                                    <h2 className='text-green-500 font-bold title'>Explore all <span className='text-primary'>Artwork</span> and <span className='text-primary'>Copy prompts</span> </h2> 

                </div>
                <div className='lg:flex justify-center category'>
                    {
                        categories?.map(category => <div className='ml-4' 
                        key={category._id}
                        >
                        <Link to={`/store/${category._id}`}><button className="btn btn-outline btn-info text-white w-52 mb-2">{category.name}</button></Link>  
                        </div>)
                    }
                </div>
            </div>
           */}
            
        </div>
    );
};

export default Category;