import React, { useEffect, useState, useContext } from "react";
import NewAllDisplayCard from "./NewAllDisplayCard";
import "./NewCard.css";
// axios
import axios from "axios";

// for generating custom id
import nextId, { setPrefix } from "react-id-generator";

//lazy loading
import LazyLoad from "react-lazyload";
//loading spiner
import { ColorRing } from "react-loader-spinner";

// react ga
import ReactGA from "react-ga";

//context
import { QueryContext } from "../../App";

// icon
import { HiOutlineSearch, HiOutlineEmojiSad } from "react-icons/hi";
import { VscChromeClose } from "react-icons/vsc";

const NewAllCard = () => {
	const [cards, setCards] = useState();

	const [items, setItems] = useState([]);
	const [page, setPage] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const [searchQuery, setSearchQuery] = useState("");

	//query and setquery from context
	const { query, setQuery } = useContext(QueryContext);

	setPrefix("key-id-for-card");
	//queru string from local storage

	//use effect for google analytics
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	const getALLCards = async () => {
		setLoading(true);
		try {
			const { data } = await axios.get(
				"https://api-prompt-production-05b5.up.railway.app/api/prompts/"
			);

			// console.log(data);
			setCards(data.data);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			setError(true);
			console.log(error);
		}
	};

	const getCards = async () => {
		if (!query) return;
		setLoading(true);

		const formData = new FormData();

		formData.append("query", query);
		try {
			const { data } = await axios.post(
				"https://api-prompt-production-05b5.up.railway.app/api/prompts/search/",
				formData
			);
			// console.log("get data with query", data.data);
			if (data.data == "No Data Found") {
				setCards([]);
				setItems([]);
				setPage(0);
				setLoading(false);
				return;
			}
			setCards(data.data);
			setItems([]);
			setPage(0);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			setError(true);
			console.log(error);
		}
	};

	useEffect(() => {
		if (query) {
			getCards();
		} else {
			getALLCards();
		}
	}, [query]);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!searchQuery) return;
		setQuery(searchQuery);
		e.target.reset();
	};

	// Load the next page of items
	const loadNextPage = () => {
		if (loading) return;
		setLoading(true);
		const newItems = fetchNextPage(page) || [];
		if (newItems.length === 0) {
			setLoading(false);
			return;
		}
		setPage(page + 1);
		setItems([...items, ...newItems]);
		// console.log("items", page);
		setLoading(false);
	};

	const fetchNextPage = (pageNumber) => {
		if (!cards) return;
		const page = pageNumber || 0;
		const pageSize = 12;
		const startIndex = page * pageSize;
		const endIndex = startIndex + pageSize;
		const newItems = cards.slice(startIndex, endIndex);
		return newItems;
	};

	const handleScroll = () => {
		const threshold = 100;
		if (
			window.innerHeight + document.documentElement.scrollTop >=
				document.documentElement.offsetHeight - threshold &&
			!loading
		) {
			loadNextPage();
		}
	};

	window.addEventListener("scroll", handleScroll);

	useEffect(() => {
		if (!cards) return;

		loadNextPage();
	}, [cards]);

	return (
		<div className="bg-black overflow-hidden md:pt-20 pt-6">
			<div className="">
				<div className=" text-center">
					<h2 className="mx-[1px]  md:text-5xl text-2xl text-white ">
						<span className="text-indigo-400 font-bold">Rethink</span>{" "}
						your next design with AI
					</h2>
				</div>
				<div className=" mx-4 text-center md:flex md:flex-col items-center text-gray-400 md:text-2xl text-[15px] mb-4 ">
					<span className="md:block">
						Explore Millions of AI generated Artwork for your next
						Website, Logo and Youtube Videos
					</span>
					{/* <span className="md:block"></span> */}
				</div>
				<form
					onSubmit={handleSubmit}
					className="flex-1 mb-2 md:mr-0 mx-4  space-x-[3px]  flex md:hidden justify-center items-center"
				>
					<input
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						type="text"
						placeholder="Search AI Images"
						className="px-2 py-[6px] w-full  text-gray-400 max-w-[500px] rounded-none   bg-gray-800 focus:outline-none"
					/>
					{/* <div className="relative"> */}
					<button className="py-[6px] relative  border-gray-900 cursor-pointer px-4 flex items-center justify-center  bg-gray-800 active:bg-gray-900 ">
						<HiOutlineSearch className=" text-2xl  text-gray-500  hover:text-gray-300" />
					</button>
					{/* <span
							onClick={() => setSearchQuery("")}
							className={`text-2xl ${
								searchQuery === "" && "hidden"
							}  absolute top-0 right-[0px] active:bg-gray-800 rounded-full p-1  `}
						></span>
					</div> */}
				</form>
				{/* <h1>{cards.length}</h1> */}
			</div>
			<div className=" flex flex-col  items-center ">
				{/* <div className=" max-w-[1200px] mx-auto lg:columns-3  sm:columns-2 columns-1  gap-x-4	 space-y-6 "> */}
				<div className=" max-w-[1200px] mx-4 md:mx-0 lg:columns-3 grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1  sm:columns-2 columns-1  gap-x-4	 space-y-6 ">
					{items?.length > 0 &&
						items?.map((card) => (
							<div className=" " key={nextId()}>
								<LazyLoad height={450}>
									<NewAllDisplayCard card={card}></NewAllDisplayCard>
								</LazyLoad>
							</div>
						))}
				</div>
				{!loading && items?.length === 0 && !loading && !error && (
					<div className="my-1 flex flex-col justify-center items-center  text-base text-gray-500">
						<span className="">
							<HiOutlineEmojiSad className="text-gray-500 text-6xl" />
						</span>
						<span className="text-center px-4">
							Sorry, we don't have any results for this query.
							<br />
							<br />
							But the good news is, we are adding 50 images per minute in
							our database, so can checkout tomorrow.
						</span>
					</div>
				)}
				{loading ? (
					<div className=" flex justify-center items-center md:text-2xl text-xl text-white">
						<ColorRing
							visible={true}
							height="80"
							width="80"
							ariaLabel="blocks-loading"
							wrapperStyle={{}}
							wrapperClass="blocks-wrapper"
							colors={[
								"#475569",
								"#475569",
								"#475569",
								"#475569",
								"#475569",
							]}
						/>
					</div>
				) : (
					error && (
						<div className="my-1 flex justify-center items-center md:text-lg text-xl text-white">
							Something went wrong ,Referesh the page
						</div>
					)
				)}
			</div>
		</div>
	);
};

export default NewAllCard;
