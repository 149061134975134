import React, { createContext, useState } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Menu from "./component/Menu";
import Foot from "./component/Foot";
import Card from "./component/Card";
import "./App.css";
import Search from "./component/Search/Search";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from "./Layout/Main";
import Store from "./component/Store/Store";
import ErrorPage from "./component/ErrorPage/ErrorPage";
import Category from "./component/Catrgory/Category";
import NewAllCard from "./component/NewAllCard/NewAllCard";

//google analytics
import ReactGA from "react-ga";
import HowToUseMidjourney from "./component/how to use midjourney/HowToUseMidjourney";
import About from "./component/about/About";
ReactGA.initialize("UA-252778651-1");

export const QueryContext = createContext();

function App() {
	const [query, setQuery] = useState("");

	const router = createBrowserRouter([
		{
			path: "/",
			element: <Main></Main>,
			errorElement: <ErrorPage></ErrorPage>,
			children: [
				{
					path: "/",
					element: <NewAllCard></NewAllCard>,
				},

				{
					path: "/meet",
					element: <About />,
				},
				{
					path: "/how-to-generate-ai-art",
					element: <HowToUseMidjourney />,
				},

				{
					path: "/store/:id",
					element: <Store></Store>,
					loader: async ({ params }) => {
						return fetch(
							`https://ai-project-server.vercel.app/store/${params.id}`
						);
					},
				},
			],
		},
	]);

	//{" "}
	// 	<div className="app">
	// 		 <Menu />
	// 		 {/* <Card/> */}
	// 		 <Search></Search>
	// 		<Foot />
	// 		{" "}
	// </div>
	return (
		<QueryContext.Provider value={{ query, setQuery }}>
			<div className="app">
				<RouterProvider router={router}></RouterProvider>
			</div>
		</QueryContext.Provider>
	);
}

export default App;
